<template>
  <div class="Users bg-white">
    <Row class="px-1">
      <i-col span="22" class="align-justify">
        <Button type="default" icon="md-refresh" size="large" class="mr-1" @click="reloadPage"></Button>
        <Button type="default" icon="md-add" size="large" class="mr-1" @click="addUser"></Button>
        <Select
          class="mr-1"
          size="large"
          style="width: 5rem"
          v-model="genderstr"
          placeholder="性别"
          @on-select="selectGender"
        >
          <Option v-for="item in genderList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
        <!-- <Cascader
          :data="companyData"
          class="el-inline mr-1"
          size="large"
          placeholder="选择所属单位/部门"
          style="width: 25rem"
          @on-change="checkCascader"
        ></Cascader>-->
        <i-input
          v-model="keyword"
          size="large"
          style="width: 15rem"
          class="el-inline mr-1"
          placeholder="输入用户名或者手机号码进行查询"
        ></i-input>
        <Button type="primary" size="large" class="mr-1" @click="HandleSearch">搜索用户</Button>
        <Button type="default" size="large" class="mr-1" @click="batchDelete(deOrRe)">批量{{ deOrRe }}</Button>
        <!-- <Button type="default" size="large" class="mr-1" @click="exportExcel">批量导出</Button> -->
        <!-- <Button type="default" size="large" class="mr-1" @click="importExcel">批量导入</Button> -->
      </i-col>
      <i-col span="2" class="align-right colHeigth">
        <Checkbox @on-change="changeStatus">被移除用户</Checkbox>
      </i-col>
      <i-col span="24">
        <Table
          border
          context-menu
          show-context-menu
          ref="usersTable"
          :loading="searching"
          :columns="tColumns"
          :data="tData"
          @on-selection-change="checkRow"
          class="mt-1"
        >
          <div slot="contextMenu">
            <DropdownItem @click.native="assessmentRecord">测评档案</DropdownItem>
            <DropdownItem @click.native="consultRecord">咨询档案</DropdownItem>
            <DropdownItem @click.native="assoUsers">关联用户</DropdownItem>
          </div>
          <div slot-scope="{ row }" slot="action">
            <Button
              type="primary"
              size="small"
              style="margin-right: 0.25rem"
              @click="showUser(row)"
            >详情</Button>
            <Button size="small" @click="HandleUser(row,deOrRe)">{{ deOrRe }}</Button>
          </div>
        </Table>
        <Page
          ref="usersPagination"
          :total="recordTotal"
          :page-size="20"
          @on-change="changePage"
          @on-page-size-change="changeSize"
          show-total
          show-sizer
          show-elevator
          class="p-1 align-right"
        />
      </i-col>
    </Row>
    <Drawer
      v-model="showAddWindow"
      title="添加用户"
      width="480"
      :mask-closable="false"
      :styles="styles"
    >
      <Form :model="userInfo">
        <Row :gutter="8" class="px-1">
          <i-col span="24">
            <Row :gutter="8">
              <i-col span="24">
                <FormItem label="用户姓名">
                  <i-input v-model="userInfo.RealName"></i-input>
                </FormItem>
              </i-col>
              <i-col span="24">
                <FormItem label="登录账号">
                  <i-input v-model="userInfo.LoginName"></i-input>
                </FormItem>
              </i-col>
              <i-col span="24">
                <FormItem label="登录密码">
                  <i-input v-model="userInfo.PassWord" placeholder="如不填，则默认为123"></i-input>
                </FormItem>
              </i-col>
              <i-col span="24">
                <FormItem label="手机号码">
                  <i-input v-model="userInfo.Phone"></i-input>
                </FormItem>
              </i-col>
              <i-col span="24">
                <FormItem label="出生日期" style="text-align: left;">
                  <DatePicker
                    v-model="userInfo.Birthday"
                    type="date"
                    placeholder="选择日期"
                    format="yyyy-MM-dd"
                    style="width: 100%;"
                  ></DatePicker>
                </FormItem>
              </i-col>
              <i-col span="24">
                <FormItem label="用户性别">
                  <RadioGroup v-model="userInfo.Gender" style="width: 100%; text-align: left;">
                    <Radio :label="1" style="padding-right:1rem;">男</Radio>
                    <Radio :label="0" style="padding-right:1rem">女</Radio>
                  </RadioGroup>
                </FormItem>
              </i-col>
            </Row>
            <Button
              type="primary"
              style="float:right;margin-left:10px;"
              :loading="modalLoading"
              @click="insertUser"
            >确认添加</Button>
            <Button style="float:right" @click="cancelAdd">取消</Button>
          </i-col>
        </Row>
      </Form>
    </Drawer>
    <modal
      v-model="showImport"
      scrollable
      footer-hide
      title="批量导入用户"
      ok-text="导入"
      cancel-text="取消"
      width="550"
      :mask-closable="false"
    >
      <div style="line-height: 3rem;">
        下载导入模板:
        <Button icon="ios-cloud-download-outline" @click="downloadExcel">导入用户模板</Button>
      </div>
      <Upload type="drag" :before-upload="handleUpload" action>
        <div style="padding: 20px 0">
          <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
          <p>点击或拖动文件到这里上传</p>
        </div>
      </Upload>
      <div v-if="file !== null" style="line-height: 3rem;">
        已选择文件:
        <span style="color:#2d8cf0;margin-right:15px;">{{ file.name }}</span>
        <Button
          type="primary"
          @click="uploadExcel"
          :loading="uploadStatus"
        >{{ uploadStatus ? '导入中···' : '开始导入' }}</Button>
      </div>
    </modal>
    <Drawer title="用户详情" v-model="draw" width="600" :mask-closable="false" :styles="styles">
      <Form :model="userDetail">
        <Row :gutter="32">
          <i-col span="12">
            <FormItem label="真实姓名" label-position="top">
              <Input v-model="userDetail.realName" />
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="用户性别" label-position="top">
              <RadioGroup v-model="userDetail.gender">
                <Radio :label="1" border>男</Radio>
                <Radio :label="0" border>女</Radio>
              </RadioGroup>
            </FormItem>
          </i-col>
          <i-col span="4"></i-col>
        </Row>
        <Row :gutter="32">
          <i-col span="12">
            <FormItem label="登录账号" label-position="top">
              <Input v-model="userDetail.loginName" readonly />
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="手机号码" label-position="top">
              <Input v-model="userDetail.phone" />
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="32">
          <i-col span="12">
            <FormItem label="学历信息" label-position="top">
              <Select v-model="userDetail.educationId">
                <Option
                  v-for="item in eduList"
                  :value="item.value"
                  :key="item.value"
                >{{ item.label }}</Option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="职业信息" label-position="top">
              <Select v-model="userDetail.professId">
                <Option
                  v-for="item in professList"
                  :value="item.value"
                  :key="item.value"
                >{{ item.label }}</Option>
              </Select>
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="32">
          <i-col span="12">
            <FormItem label="所属行业" label-position="top">
              <Select v-model="userDetail.industryId">
                <Option
                  v-for="item in industryList"
                  :value="item.value"
                  :key="item.value"
                >{{ item.label }}</Option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="婚姻状态" label-position="top">
              <Select v-model="userDetail.maritalId">
                <Option
                  v-for="item in marriedList"
                  :value="item.value"
                  :key="item.value"
                >{{ item.label }}</Option>
              </Select>
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="32">
          <i-col span="12">
            <FormItem label="注册时间" label-position="top">
              <DatePicker
                v-model="userDetail.registerTime"
                type="datetime"
                readonly
                style="display: block"
                placement="bottom-end"
              ></DatePicker>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="出生日期" label-position="top">
              <DatePicker
                v-model="userDetail.ageTime"
                type="date"
                style="display: block"
                placement="bottom-end"
              ></DatePicker>
            </FormItem>
          </i-col>
        </Row>
        <FormItem label="所属单位" label-position="top">
          <Cascader :data="companyData" disabled v-model="cascaderValue" size="large"></Cascader>
        </FormItem>
      </Form>
      <div class="demo-drawer-footer">
        <Button style="margin-right: 8px" @click="closeDraw">取消</Button>
        <Poptip confirm title="确定修改用户信息?" @on-ok="updateUser" @on-cancel="cancelUpdate">
          <Button type="primary">修改</Button>
        </Poptip>
      </div>
    </Drawer>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Users",
  data() {
    return {
      batchIds: [],
      draw: false,
      styles: {
        height: "calc(100% - 55px)",
        overflow: "auto",
        paddingBottom: "53px",
        position: "static",
      },
      cascaderValue: [],
      uploadStatus: false,
      file: null,
      eduList: [],
      professList: [],
      industryList: [],
      marriedList: [],
      showAddWindow: false,
      showImport: false,
      userInfo: {
        RealName: "",
        UserName: "",
        Phone: "",
        Gender: 1,
        Birthday: "",
        CompanyId: -1,
        CompanyName: "",
        EducationId: -1,
        EducationName: "",
        ProfessId: -1,
        ProfessName: "",
        IndustryId: -1,
        IndustryName: "",
        MaritalId: -1,
        MaritalName: "",
      },
      modalLoading: false,
      tData: [],
      keyword: "",
      deOrRe: "移除",
      theight: window.innerHeight - 175,
      isShow: true,
      bindStatus: 1,
      tColumns: [
        {
          type: "selection",
          width: 35,
          align: "center",
        },
        {
          type: "index",
          title: "序号",
          width: 45,
          align: "center",
        },
        {
          title: "登录名",
          key: "loginName",
          minWidth: 80,
        },
        // {
        //   title: "用户名",
        //   key: "userName",
        //   minWidth: 100,
        // },
        {
          title: "姓名",
          key: "realName",
          minWidth: 100,
        },
        {
          title: "性别",
          key: "gender",
          minWidth: 60,
          render: (h, params) => {
            return h("span", this.myjs.getGender(params.row.gender));
          },
        },
        {
          title: "年龄",
          key: "age",
          render: (h, params) => {
            let age = this.myjs.getAge(params.row.ageTime, new Date());
            return h("span", age);
          },
          minWidth: 60,
        },
        {
          title: "手机号",
          key: "phone",
          minWidth: 80,
        },
        {
          title: "所属单位",
          key: "companyName",
          minWidth: 230,
          ellipsis: true,
          tooltip: true,
          render: (h, params) => {
            return h("span", this.myjs.FormatEmpty(params.row.companyName));
          },
        },
        {
          title: "注册时间",
          key: "registerTime",
          width: 180,
          render: (h, params) => {
            let dt = this.myjs.getDateTimeNumCutT(params.row.registerTime);
            return h("span", dt);
          },
          sortable: true,
          sortType: "desc",
        },
        {
          title: "操作",
          slot: "action",
          width: 102,
        },
      ],
      gender: -1,
      genderstr: "",
      genderList: [
        {
          value: 1,
          label: "男",
        },
        {
          value: 0,
          label: "女",
        },
      ],
      companyId: -1,
      companyName: "",
      loading: false,
      searchCompanyList: [],
      companylist: [],
      pageSize: 20,
      recordTotal: 0,
      pageNum: 1,
      searching: true,
      companyData: [],
      userDetail: {},
    };
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => (this.theight = window.innerHeight - 175),
      false
    );
    if (this.$route.query.companyId == undefined) {
      this.companyId = -1;
    } else {
      this.companyId = this.$route.query.companyId;
    }
    // 获取用户列表
    this.$get("Customer/GetCustomerList", {
      companyId: this.companyId,
      gender: -1,
      keyword: "",
      statusCode: 1,
      pageSize: 20,
      pageNum: 1,
    }).then((Customer) => {
      this.tData = Customer.data.responseList;
      this.recordTotal = Customer.data.total;
      this.searching = false;
      this.getMarriedList();
      this.getIndustryList();
      this.getEduList();
      this.getProfessList();
      this.getCompanyByCascader();
    });
  },
  methods: {
    batchDelete(val) {
      if (this.batchIds.length <= 0) {
        this.$Message.error({
          content: "请选择需要操作的行",
          duration: 3,
        });
        return;
      }
      let batchIds = "";
      for (let i = 0; i < this.batchIds.length; i++) {
        batchIds += this.batchIds[i].userId + ",";
      }
      if (val == "移除") {
        this.$Modal.confirm({
          title: "提示",
          content: "是否移除？",
          onOk: () => {
            this.$post("Customer/BatchDeleteCustomer", { ids: batchIds }).then(
              (res) => {
                if (res.data.code) {
                  this.$Modal.remove();
                  this.$Message.success({
                    content: "移除成功",
                    duration: 3,
                  });
                  this.getUser();
                } else {
                  this.$Modal.remove();
                  this.$Message.error({
                    content: "移除失败，请重试",
                    duration: 3,
                  });
                }
              }
            );
          },
          onCancel: () => {},
        });
      } else {
        this.$post("Customer/BatchReductionCustomer", { ids: batchIds }).then(
          (res) => {
            if (res.data.code) {
              this.$Message.success({
                content: "还原成功",
                duration: 3,
              });
              this.getUser();
            } else {
              this.$Message.error({
                content: "还原失败，请重试",
                duration: 3,
              });
            }
          }
        );
      }
    },
    checkRow(e) {
      this.batchIds = e;
    },
    checkCascader(value, selectedData) {
      console.log(value);
      if (value.length == 0) {
        this.companyId = -1;
      } else {
        this.companyId = value[2];
      }
      this.pageNum = 1;
      this.getUser();
    },
    handleUpload(file) {
      this.file = file;
      return false;
    },
    // 批量导入用户信息弹窗
    importExcel() {
      this.showImport = true;
    },
    downloadExcel() {
      window.location.href =
        "https://fzapi.hxypsy.cn/Uploads/批量添加用户模板.xlsx";
      // this.$get("Customer/GetUserTemplate").then((res) => {
      //   console.log(res);
      // });
    },
    uploadExcel() {
      let arr = ["xls", "xlsx"];
      this.uploadStatus = true;
      //获取最后一个.的位置
      var index = this.file.name.lastIndexOf(".");
      //获取后缀
      var ext = this.file.name.substr(index + 1);
      //输出结果
      if (!arr.includes(ext)) {
        this.uploadStatus = false;
        this.$Modal.error({
          title: "错误！",
          content: "文件：" + this.file.name + " 格式不正确, 请选择Excel文件.",
        });
      } else {
        var file = new FormData();
        file.append("file", this.file);
        axios
          .post("Customer/UploadExcel", file, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.data.code) {
              this.file = null;
              this.showImport = false;
              this.uploadStatus = false;
              this.$Modal.info({
                title: "导入信息",
                content: res.data.msg,
                onOk: () => {
                  this.getUser();
                },
              });
            } else {
              this.uploadStatus = false;
              this.$Modal.error({
                title: "导入失败",
                content: res.data.msg,
              });
            }
          });
      }
    },
    // 批量导出用户信息
    exportExcel() {},
    showUser(row) {
      let keys = new Array();
      if (row.concatCompanyId != null) {
        keys = row.concatCompanyId.split(">");
        let key1 = parseInt(keys[0]);
        let key2 = parseInt(keys[1]);
        let key3 = parseInt(keys[2]);
        this.cascaderValue = [key1, key2, key3];
      }
      this.userDetail = row;
      console.log(row);
      this.draw = true;
    },
    closeDraw() {
      this.draw = false;
    },
    cancelUpdate() {},
    updateUser() {
      this.userDetail.companyId = this.cascaderValue[2];
      let data = this.userDetail;
      this.$post("Customer/UpdateUser", data).then((res) => {
        if (res.data.code) {
          this.$Message.success(res.data.msg);
          this.draw = false;
        } else {
          this.$Modal.error({
            title: "提示",
            content: "修改信息失败，请重试",
          });
        }
      });
    },
    HandleUser(row, deOrRe) {
      if (deOrRe == "移除") {
        this.$Modal.confirm({
          title: "提示",
          content: "是否移除？",
          onOk: () => {
            this.$post("Customer/DeleteCustomer", {
              userId: row.userId,
            }).then((res) => {
              if (res.data.code) {
                this.$Modal.remove();
                this.$Message.success({
                  content: "移除成功",
                  duration: 3,
                });
                this.getUser();
              } else {
                this.$Modal.remove();
                this.$Message.error({
                  content: "移除失败，请重试",
                  duration: 3,
                });
              }
            });
          },
          onCancel: () => {},
        });
      } else {
        this.$post("Customer/ReductionCustomer", {
          userId: row.userId,
        }).then((res) => {
          if (res.data.code) {
            this.$Message.success({
              content: "还原成功",
              duration: 3,
            });
            this.getUser();
          } else {
            this.$Message.error({
              content: "还原失败，请重试",
              duration: 3,
            });
          }
        });
      }
    },
    cancelAdd() {
      this.showAddWindow = false;
    },
    insertUser() {
      this.modalLoading = true;
      const teshu = new RegExp(
        "[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、 ？]"
      );
      const hanzi = /[\u4e00-\u9fa5]/;

      if (
        hanzi.test(this.userInfo.LoginName) ||
        teshu.test(this.userInfo.LoginName)
      ) {
        this.modalLoading = false;
        this.$Message.error({
          content: "登录名只允许输入英文或数字",
          duration: 2,
        });
        return;
      }
      if (this.userInfo.PassWord != "") {
        if (
          hanzi.test(this.userInfo.PassWord) ||
          teshu.test(this.userInfo.PassWord)
        ) {
          this.modalLoading = false;
          this.$Message.error({
            content: "密码只允许输入英文或数字",
            duration: 2,
          });
          return;
        }
      }
      this.userInfo.birthday = this.myjs.formatGMT(this.userInfo.birthday);
      if (this.userInfo.RealName == "") {
        this.modalLoading = false;
        this.$Message.error({
          content: "请填写姓名",
          duration: 3,
        });
        return;
      }
      if (this.userInfo.Birthday == "") {
        this.modalLoading = false;
        this.$Message.error({
          content: "请选择出生日期",
          duration: 3,
        });
        return;
      }
      this.$post("Customer/InsertCustomer", {
        RealName: this.userInfo.RealName,
        UserName: this.userInfo.UserName,
        LoginName: this.userInfo.LoginName,
        PassWord_: this.userInfo.PassWord,
        Phone: this.userInfo.Phone,
        Gender: this.userInfo.Gender,
        Birthday: this.userInfo.Birthday,
        EducationId: this.userInfo.EducationId,
        ProfessId: this.userInfo.ProfessId,
        IndustryId: this.userInfo.IndustryId,
        CompanyId: this.userInfo.CompanyId,
        MaritalId: this.userInfo.MaritalId,
      }).then((res) => {
        this.modalLoading = false;
        if (res.data.code) {
          this.$Message.success({
            content: "添加成功",
            duration: 3,
          });
          this.showAddWindow = false;
          this.getUser();
        } else {
          this.$Message.error({
            content: "添加失败，请重试",
            duration: 3,
          });
        }
      });
    },
    cancelAdd() {
      this.showAddWindow = false;
    },
    addUser() {
      this.showAddWindow = true;
    },
    reloadPage() {},
    changeSize(e) {
      this.pageSize = e;
      this.getUser();
    },
    changePage(e) {
      this.pageNum = e;
      this.getUser();
    },
    searchCompany(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          const list = this.companylist.map((item) => {
            return {
              value: item.value,
              label: item.label,
            };
          });
          this.searchCompanyList = list.filter(
            (item) => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
          );
        }, 200);
      } else {
        this.searchCompanyList = [];
      }
    },
    // 选择公司时搜索
    selectCompany(e) {
      this.companyId = e.value;
      this.pageNum = 1;
      this.getUser();
    },
    // 选择性别时搜索
    selectGender(e) {
      this.gender = e.value;
      this.pageNum = 1;
      this.getUser();
    },
    changeStatus(k) {
      if (k) {
        this.deOrRe = "还原";
        this.isShow = false;
        this.bindStatus = 0;
      } else {
        this.deOrRe = "移除";
        this.isShow = true;
        this.bindStatus = 1;
      }
      this.pageNum = 1;
      this.getUser();
    },
    assessmentRecord() {},
    consultRecord() {},
    assoUsers() {},
    HandleSearch() {
      this.pageNum = 1;
      this.getUser();
    },
    getUser() {
      this.searching = true;
      // 获取用户列表
      this.$get("Customer/GetCustomerList", {
        companyId: this.companyId,
        gender: this.gender,
        keyword: this.keyword,
        statusCode: this.bindStatus,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      }).then((res) => {
        this.recordTotal = res.data.total;
        this.tData = res.data.responseList;
        this.searching = false;
      });
    },
    filterArray(list) {
      return list.map((item) => {
        item = Object.assign({}, item);
        if (item.level == 3) {
          delete item.children;
        } else {
          if (item.children && item.children.length > 0) {
            item.children = this.filterArray(item.children);
          }
        }
        return item;
      });
    },
    getCompanyByCascader() {
      this.$get("Customer/GetCompanyByCascader", {
        requestName: "getCompanyByCascader",
      }).then((res) => {
        this.companyData = this.filterArray(res.data.responseList);
      });
    },
    getProfessList() {
      this.$get("Customer/GetProfessList", {
        requestName: "getProfessList",
      }).then((res) => {
        this.professList = res.data.responseList;
      });
    },
    getEduList() {
      this.$get("Customer/GetEduList", {
        requestName: "getEduList",
      }).then((res) => {
        this.eduList = res.data.responseList;
      });
    },
    getIndustryList() {
      this.$get("Customer/GetIndustryList", {
        requestName: "getIndustryList",
      }).then((res) => {
        this.industryList = res.data.responseList;
      });
    },
    getMarriedList() {
      this.$get("Customer/GetMarriedList", {
        requestName: "getMarriedList",
      }).then((res) => {
        this.marriedList = res.data.responseList;
      });
    },
  },
};
</script>

<style lang="less">
</style>
